/*** IMPORTS FROM imports-loader ***/
var es6promise = require("es6-promise/auto");

// jQuery
import $ from 'jquery';
import './modernizr-custom.js';
import Swiper from 'swiper/dist/js/swiper.js';
import AOS from 'aos/dist/aos.js';
import 'basictable/jquery.basictable.min.js';
import 'jquery.counterup/jquery.counterup.min.js';

$(document).ready(async function () {
	// Init Animate On Scroll
	AOS.init({
		once: true,
		easing: 'ease-in-sine'
	});

	// Counter up
	var slideshowCounter = $('.slideshow__item:first-child .slideshow__content strong');
	if (slideshowCounter.length) {
		await import('waypoints/lib/jquery.waypoints.js');

		slideshowCounter.counterUp({
			delay: 11,
			time: 1500,
			formatter: function (n) {
				return n.replace(/,/g, '.');
			}
		});
	}

	// Search toggler
	$('.search').each(function () {
		var $this = $(this),
			toggler = $this.find('.search__toggler');

		toggler.click(function () {
			$('#search-input').focus();
			$this.toggleClass('search--closed');
			toggler.toggleClass('search__toggler--closed');
		}).appendTo($this);
	});

	// Selectize
	var selects = $('select[multiple]');
	if (selects.length) {
		await import('selectize');

		selects.selectize({
			plugins: ['remove_button']
		});
	}

	// Responsive menu
	$('.toggler').click(function () {
		var toggler = $(this);
		var responsiveNav = $('.responsive-nav');

		responsiveNav.toggleClass('-open');
		toggler.toggleClass('is-active');
	});

	// Slider in frontpage slider
	$('.slideshow').each(async function () {
		$(this).addClass('-active');

		new Swiper('.swiper-container.slideshow', {
			grabCursor: true,
			effect: 'fade',
			slidesPerView: 1,
			autoplay: {
				delay: 6000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	});

	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('.object-fit').each(function () {
			var element = $(this);
			var image = element.find('img');

			element.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'center center' });
			image.remove();
		});
	}

	// Fader
	$('.fader__control-center').each(async function () {
		// Create the control center
		var swiper = new Swiper('.fader__control-center.swiper-container', {
			loop: true,
			autoplay: {
				delay: 2500
			},
			speed: 1000
		});

		// Get the faders
		var faders = $('.fader__container');

		// Create array of fader indexes
		var faderIndexes = jQuery.map(faders, function (n, i) {
			return i;
		});

		//// Randomize the fader order
		//faders.sort(function () { return 0.5 - Math.random() });

		// Function for returening a fader to render the slide in
		var faderIndex = 0;
		var totalFaderCount = faderIndexes.length;

		function getFader() {
			// Check if fader index is below fader count, and rerandomize the faders
			if (faderIndex >= totalFaderCount) {
				faderIndex = 0;
				//Shuffle(faders);
			}

			// Get the fader to activate
			var faderToActivate = faders[faderIndex];

			// Increase the fader index
			faderIndex++;

			// Check if visible
			if (!$(faderToActivate).is(':visible')) {
				return getFader();
			} else {
				// Return the fader to activate
				return faderToActivate;
			}
		}

		// Activate the fader after the transition
		swiper.on('slideChangeTransitionEnd', function () {
			// Get the next slide and remove useless css
			var slide = $(swiper.slides[swiper.activeIndex]).clone();
			slide.css('width', '');
			slide.removeClass('swiper-slide-active');
			slide.addClass('fader__item');
			slide.addClass('-hidden');

			// Add the slide to the fader
			var fader = getFader();
			fader.append(slide[0]);

			// Add delay, otherwise the animation of the element will nog fire
			setTimeout(function () {
				slide.removeClass('-hidden');
			}, 10);

			// Remove access slides
			if ($(fader).children().length >= 3) {
				$(fader).find('.fader__item').first().remove();
			}
		});

		//// Randomly shuffle array methode
		//function Shuffle(o) {
		//    for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
		//    return o;
		//};
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	await import('waypoints/lib/jquery.waypoints.js');
	await import('waypoints/lib/shortcuts/infinite.js');

	// Paged images
	var imageGallery = $('.image-gallery');
	if (imageGallery.length) {
		await import('waypoints/lib/jquery.waypoints.js');
		await import('waypoints/lib/shortcuts/infinite.js');

		imageGallery.each(function () {
			var images = $(this);
			images.find('a[rel=next]').one('click', function (e) {
				new Waypoint.Infinite({
					element: images[0],
					container: images.find('.columns'),
					items: '.image-gallery .column',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function (items) {
						// Fade in added items
						const delay = 40;
						items.css('opacity', 0).each(function (i) {
							var item = $(this);
							setTimeout(function () {
								item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});

						$('body:has([rel=lightbox])').each(async function () {
							await import('lightgallery');

							$(this).data('lightGallery').destroy(true);
							$(this).lightGallery({
								selector: '[rel=lightbox]',
								download: false,
								thumbnail: false,
								fullScreen: false,
								actualSize: false,
								hash: false
							});
						});
					}
				});

				images.find('.pagination-container').hide();
				e.preventDefault();
			});
		});
	}

	// Responsive table
	$('table').basictable({
		breakpoint: 1024,
		forceResponsive: true
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Jump to submitted form
	$('.form').each(function () {
		var formContainer = $(this), form = formContainer.find('form');
		form.attr('action', form.attr('action') + '#' + formContainer.find('.anchor').attr('name'));
	});

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank');
});
